import React  from "react";

// componenents
import Layout from "../components/Layout";

// Todo:
// - ?

// markup
const IndexPage = ({ location }) => {
  if (typeof window == `undefined`) {
    return (
      <>
        window not found #ccz3
      </>
    )
  }
  if (!location.state) {
      throw new Error ('No state was provided for success page.')
      // return (
      //     <b>page error</b> 
      // )
  }
  console.log('state: ', location.state)

  const title = location.state.title
  const message = location.state.msg

  return (
    <Layout>
      <h2>{title}</h2>
      { message }
    </Layout>
  )
};

export default IndexPage;